import React from 'react'

const AngeloneSetupBlog = () => {
  return (
    <div className='container-fluid p-5'>
      <div className='blog_header mb-5'>
        <h1>Below are the details how to link your Angelone demat account with FreeStyle FNO</h1>
      </div>
      <div className='blog_body'>
        <div>
          <div>
            <h2 className='mb-4 text-uppercase text-info'>How to add Demat Account ?</h2>
            <div>
              <h3>Step 1 : Go To Demat Account Section</h3>
              <ul>
                <li>Open FreeStyleFno App</li>
                <li>Go To Demat Account Section</li>
              </ul>
            </div>
            <div>
              <h3>Step 2 : Click on Add Broker Button (Plus icon)</h3>
              <p>Select the Angelone option</p>
            </div>
            <div>
              <h3>Step 3 : Nick name</h3>
              <p>Enter the name of the demat account holder.</p>
            </div>
            <div>
              <h3>Step 4 : Client id</h3>
              <p>You can get the Client ID by following below steps</p>
              <ul>
                <li>Open your angelone app</li>
                <li>Go to Profile section</li>
                <li>Here you can find Client ID : example - S2302050</li>
              </ul>
            </div>
            <div>
              <h3>Step 5 : API Key & API Secret key</h3>
              <p>You can get the API Key by following below steps</p>
              <ul>
                <li>Open Your Browser (Ex. Chrome)</li>
                <li>
                  Visit the website :
                  <a href='https://smartapi.angelbroking.com/'>
                    https://smartapi.angelbroking.com/
                  </a>
                </li>
                <li>Register your account by clicking on SignUp</li>
                <li>Login into your account</li>
                <li>Click on Create an App button</li>
                <li>In Pricing model select Trading APIs</li>
                <li>In App Name enter any name : ex (FNO)</li>
                <li>
                  Enter the Redirect URL by copying the link from the FreeStyleFno App. (You can
                  find out in demat account section - where you are currently filling the
                  information)
                </li>
                <li>Click on Create App and you will get Api key and Api Secret Key</li>
              </ul>
            </div>
            <div>
              <h3>Step 6 : Login PIN</h3>
              <p>Enter your angelone app login pin here</p>
            </div>
            <div>
              <h3>Step 7 : Click on the submit button</h3>
            </div>
          </div>
          <hr />
          <div>
            <h2 className='mb-4 text-uppercase text-info'>How to daily login ?</h2>
            <div>
              <p>Please follow below steps to daily login</p>
              <div>
                <h3>If you have already set up the Google Authnticator App for FreeStyleFNO</h3>
                <ul>
                  <li>Open the Demat Account tab in FreeStyleFNO APP</li>
                  <li>Click on Your Angelone broker</li>
                  <li>Add code from Authenticator app to Authenticator code field and Submit</li>
                </ul>
              </div>
              <div>
                <h3>Otherwise Connect Google Authenticator APP with FreeStyleFNO</h3>
                <p>By following below steps</p>
              </div>
              <ul>
                <li>
                  <a href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pcampaignid=web_share'>
                    Download Google Authenticator App
                  </a>{' '}
                  in your mobile device
                </li>
                <li>Open Browser (Chrome)</li>
                <li>
                  Visit the website :{' '}
                  <a href='https://smartapi.angelbroking.com/'>
                    https://smartapi.angelbroking.com/
                  </a>
                </li>
                <li>Login into your account</li>
                <li>Click on Enable TOTP and enter Client ID and Login PIN</li>
                <li>Enter the otp and submit</li>
                <li>Scan QR with Authernticator App</li>
                <li>Open Your FreeStyle FNO App and Go To Brokers section</li>
                <li>Click on Your Angelone broker</li>
                <li>Add code from Authenticator app to Authenticator code field and Submit</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AngeloneSetupBlog
