/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../helpers'
import {getClients} from './_request'

type Props = {
  className: string
}

const ListsWidget6: React.FC<Props> = ({className}) => {
  const [clientData, setClientData] = useState<any>([])
  const [activeClient, setActiveClient] = useState<number>(0)
  const [companyProfit, setCompanyProfit] = useState<number>(0)
  useEffect(() => {
    getClients().then((res: any) => {
      setClientData(res?.data)

      const activeClientCount = res?.data?.data.filter(
        (client: any) => client.brokers.length > 0
      ).length
      setActiveClient(activeClientCount)

      const totalProfit = res?.data?.data.reduce((sum: number, client: any) => {
        return sum + (parseFloat(client.wallet_margin_usage) || 0)
      }, 0)
      setCompanyProfit(totalProfit)
    })
  }, [])

  return (
    <div className='card card-xl-stretch mb-5 mb-xl-8'>
      <div className='card-header border-0'>
        <h3 className='card-title fw-bold text-dark'>OverView</h3>
        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
        </div>
      </div>
      <div className='card-body pt-0'>
        <div className='d-flex align-items-center bg-light-warning rounded p-5 mb-7'>
          <span className='svg-icon svg-icon-warning me-5'>
            <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' />
          </span>
          <div className='flex-grow-1 me-2'>
            <h4 className='fw-bold m-0 text-gray-800 fs-4'>Active Clients</h4>
          </div>
          <span className='fw-bold text-warning py-1 fs-4'>
            {activeClient + ' / ' + clientData?.data?.length}
          </span>
        </div>
        <div className='d-flex align-items-center bg-light-success rounded p-5 mb-7'>
          <span className='svg-icon svg-icon-success me-5'>
            <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' />
          </span>
          <div className='flex-grow-1 me-2'>
            <h4 className='m-0 fw-bold text-gray-800 fs-4'>Company P & L</h4>
          </div>
          <span className='fw-bold text-success py-1 fs-4'>₹ {companyProfit?.toFixed(2)}/-</span>
        </div>
        {/* <div className='d-flex align-items-center bg-light-danger rounded p-5 mb-7'>
          <span className='svg-icon svg-icon-danger me-5'>
            <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' />
          </span>
          <div className='flex-grow-1 me-2'>
            <a href='#' className='fw-bold text-gray-800 text-hover-primary fs-6'>
              Rebrand strategy planning
            </a>
            <span className='text-muted fw-semibold d-block'>Due in 5 Days</span>
          </div>
          <span className='fw-bold text-danger py-1'>-27%</span>
        </div>
        <div className='d-flex align-items-center bg-light-info rounded p-5'>
          <span className='svg-icon svg-icon-info me-5'>
            <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' />
          </span>
          <div className='flex-grow-1 me-2'>
            <a href='#' className='fw-bold text-gray-800 text-hover-primary fs-6'>
              Product goals strategy
            </a>
            <span className='text-muted fw-semibold d-block'>Due in 7 Days</span>
          </div>
          <span className='fw-bold text-info py-1'>+8%</span>
        </div> */}
      </div>
    </div>
  )
}

export {ListsWidget6}
