import {createContext, useEffect, useState} from 'react'
import {initialQueryState, PaginationState} from '../../_metronic/helpers'
export const generalContext = createContext<any>(null)

function Context({children}: {children: React.ReactNode}) {
  const [currentPage, setCurrentPage] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(initialQueryState?.items_per_page)

  return (
    <generalContext.Provider
      value={{
        currentPage,
        setCurrentPage,
        itemsPerPage,
        setItemsPerPage,
      }}
    >
      {children}
    </generalContext.Provider>
  )
}

export default Context
