import React from 'react'
import LandingLayout from '../landing/LandingLayout'

const TermsAndCondition = () => {
  return (
    <LandingLayout>
      <section className='policy-page'>
        <div className='w-100'>
          <div className='container'>
            <div className='row h-100 align-items-center'>
              <div className='col-12'>
                <div className='position-relative'>
                  <div className='py-5'>
                    <h1 className='mb-5'>Terms and Conditions</h1>
                    <h2 className='mb-3'>Introduction</h2>
                    <p className='mb-3'>
                      Welcome to FreeStyle InfoTech LLP. These Terms and Conditions ("Terms", "Terms and
                      Conditions") govern your relationship with FreeStyle InfoTech LLP's website and
                      services (the "Service") operated by FreeStyle InfoTech LLP ("us", "we", or
                      "our").
                    </p>
                    <p className='mb-3'>
                      Please read these Terms and Conditions carefully before using our Service.
                      Your access to and use of the Service is conditioned upon your acceptance of
                      and compliance with these Terms. These Terms apply to all visitors, users, and
                      others who wish to access or use the Service.
                    </p>
                    <p className='mb-4'>
                      By accessing or using the Service, you agree to be bound by these Terms. If
                      you disagree with any part of the terms, then you do not have permission to
                      access the Service.
                    </p>

                    <h2 className='mb-3'>Accounts</h2>
                    <p className='mb-3'>
                      When you create an account with us, you guarantee that you are above the age
                      of 18 and that the information you provide us is accurate, complete, and
                      current at all times. Inaccurate, incomplete, or obsolete information may
                      result in the immediate termination of your account on the Service.
                    </p>
                    <p className='mb-3'>
                      You are responsible for maintaining the confidentiality of your account and
                      password, including but not limited to the restriction of access to your
                      computer and/or account. You agree to accept responsibility for any and all
                      activities or actions that occur under your account and/or password, whether
                      your password is with our Service or a third-party service. You must notify us
                      immediately upon becoming aware of any breach of security or unauthorized use
                      of your account.
                    </p>

                    <h2 className='mb-3'>Intellectual Property</h2>
                    <p className='mb-3'>
                      The Service and its original content, features, and functionality are and will
                      remain the exclusive property of FreeStyle InfoTech LLP and its licensors. The
                      Service is protected by copyright, trademark, and other laws of both the
                      United States and foreign countries. Our trademarks and trade dress may not be
                      used in connection with any product or service without the prior written
                      consent of FreeStyle InfoTech LLP.
                    </p>

                    <h2 className='mb-3'>Links to Other Web Sites</h2>
                    <p className='mb-3'>
                      Our Service may contain links to third-party web sites or services that are
                      not owned or controlled by FreeStyle InfoTech LLP.
                    </p>
                    <p className='mb-3'>
                      FreeStyle InfoTech LLP has no control over, and assumes no responsibility for the
                      content, privacy policies, or practices of any third-party web sites or
                      services. We do not warrant the offerings of any of these entities/individuals
                      or their websites.
                    </p>
                    <p className='mb-3'>
                      You acknowledge and agree that FreeStyle InfoTech LLP shall not be responsible or
                      liable, directly or indirectly, for any damage or loss caused or alleged to be
                      caused by or in connection with use of or reliance on any such content, goods,
                      or services available on or through any such third-party web sites or
                      services.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </LandingLayout>
  )
}

export default TermsAndCondition
