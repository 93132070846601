import {PageTitle} from '../../../_metronic/layout/core'
import {
  ListsWidget2,
  StatisticsWidget5,
  ChartsWidget2,
  ListsWidget6,
} from '../../../_metronic/partials/widgets'

const DashboardPage = () => (
  <>
    {/* begin::Row */}
    <div className='row g-5 g-xl-8'>
      <div className='col-xl-4'>
        <StatisticsWidget5
          to='/clients'
          className='card-xl-stretch mb-xl-8'
          svgIcon='/media/icons/duotune/general/gen049.svg'
          color='danger'
          iconColor='white'
          title='Clients'
          description=''
          titleColor='white'
          descriptionColor='white'
        />
      </div>

      <div className='col-xl-4'>
        <StatisticsWidget5
          to='/trades'
          className='card-xl-stretch mb-xl-8'
          svgIcon='/media/icons/duotune/arrows/arr070.svg'
          color='primary'
          iconColor='white'
          title='Trades'
          description=''
          titleColor='white'
          descriptionColor='white'
        />
      </div>

      <div className='col-xl-4'>
        <StatisticsWidget5
          to='/positions'
          className='card-xl-stretch mb-5 mb-xl-8'
          svgIcon='/media/icons/duotune/general/gen002.svg'
          color='success'
          iconColor='white'
          title='Positions'
          description=''
          titleColor='gray-100'
          descriptionColor='gray-100'
        />
      </div>

      <div className='col-xl-4'>
        <StatisticsWidget5
          to='/latest-demat-orders'
          className='card-xl-stretch mb-5 mb-xl-8'
          svgIcon='/media/icons/duotune/communication/com004.svg'
          color='info'
          iconColor='white'
          title='Latest Demat Order'
          description=''
          titleColor='gray-100'
          descriptionColor='gray-100'
        />
      </div>

      <div className='col-xl-4'>
        <StatisticsWidget5
          to='/instruments'
          className='card-xl-stretch mb-5 mb-xl-8'
          svgIcon='/media/icons/duotune/general/gen009.svg'
          color='warning'
          iconColor='gray-100'
          title='Instruments'
          description=''
          titleColor='gray-100'
          descriptionColor='gray-100'
        />
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row'>
      <div className='col-12 col-md-6'>
        <ListsWidget2 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-12 col-md-6'>
        <ListsWidget6 className='card-xl-stretch mb-xl-8' />
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row'>
      {/* begin::Col */}
      <div className='col-12'>
        <ChartsWidget2 className='card-xxl-stretch mb-5 mb-xl-10' />
      </div>
      {/* end::Col */}
    </div>
    {/* end::Row */}
  </>
)

const DashboardWrapper = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Dashboard</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
