import React, {useEffect} from 'react'
import LandingHeader from './LandingHeader'
import LandingFooter from './LandingFooter'

const LandingLayout = ({children}: any) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])
  return (
    <>
      <LandingHeader />
      <>{children}</>
      <LandingFooter />
    </>
  )
}

export default LandingLayout
