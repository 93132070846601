import React from 'react'
import LandingLayout from '../landing/LandingLayout'

const RefundPolicy = () => {
  return (
    <LandingLayout>
      <section className='policy-page'>
        <div className='w-100'>
          <div className='container'>
            <div className='row h-100 align-items-center'>
              <div className='col-12'>
                <div className='position-relative'>
                  <div className='py-5'>
                    <h1 className='mb-3'>Refund Policy</h1>
                    <h2 className='mb-3'>Overview</h2>
                    <p className='mb-4'>
                      At FreeStyle InfoTech LLP, we are committed to providing exceptional digital
                      services to our customers. If you are not entirely satisfied with your
                      purchase made through our online payment gateway, we’re here to help.
                    </p>

                    <h2 className='mb-3'>Refunds</h2>
                    <h3 className='mb-3'>Eligibility for Refunds</h3>
                    <ul className='mb-4'>
                      <li>
                        To be eligible for a refund, you must request it within 5 working days of
                        your purchase.
                      </li>
                      <li>
                        The service must be unused and in the same condition that you received it.
                      </li>
                      <li>Proof of purchase (e.g., receipt or order number) must be provided.</li>
                    </ul>

                    <h3 className='mb-3'>Non-refundable Items</h3>
                    <p className='mb-4'>
                      Certain types of services are exempt from being refunded. These include:
                    </p>
                    <ul className='mb-4'>
                      <li>Any digital services once they have been accessed or downloaded.</li>
                    </ul>

                    <h2 className='mb-3'>Process for Refunds</h2>
                    <h3 className='mb-3'>Request a Refund</h3>
                    <p className='mb-4'>
                      To initiate a refund, please contact our customer service team at{' '}
                      <a href='mailto:info@freestyleinfotech.com'>info@freestyleinfotech.com</a> or
                      call us at +91 9979688595 with your order number and details about the service
                      you would like to get a refund for.
                    </p>

                    <h3 className='mb-3'>Approval and Instructions</h3>
                    <p className='mb-4'>
                      Once your request is received, we will review it and notify you via email
                      regarding the approval or rejection of your refund. If approved, we will
                      provide you with further instructions on how to proceed.
                    </p>

                    <h3 className='mb-3'>Inspection and Processing</h3>
                    <p className='mb-4'>
                      We will evaluate your refund request based on the service usage and our terms
                      and conditions. If approved, your refund will be processed, and a credit will
                      automatically be applied to your original method of payment within a 15-20 days.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </LandingLayout>
  )
}

export default RefundPolicy
