import React, {useEffect, useState} from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {PageTitle} from '../../../../../../_metronic/layout/core'
import {getAccountBriefDetails} from '../../core/_requests'
import {toast} from 'react-toastify'
import moment from 'moment'
type AccountState = {
  account: any
}

const AccountDetailsBrief = () => {
  const location = useLocation()
  const state = location.state as AccountState
  const {account} = state || {}
  const navigate = useNavigate()
  const [accountBriefDetails, setAccountBriefDetails] = useState<any>(null)

  const handleNavigate = (event: any, item: any) => {
    event.preventDefault()
    navigate(`account-details-full`, {state: {account: item}})
  }

  useEffect(() => {
    if (account) {
      getAccountBriefDetails(account?._id).then((res) => {
        setAccountBriefDetails(res?.data?.data)
      })
    }
  }, [account])

  if (!account) {
    return <div>No account data found.</div>
  }
  const handleBackClick = () => {
    window.history.back()
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{account?.account_name}</PageTitle>
      <div>
        <div className='card card-custom card-flush h-auto'>
          <div className='card-header'>
            <h5 className='card-title' style={{fontSize: '22px'}}>
              {account?.account_name}
              <a href='' className='px-5'>
                <i className='fa-regular fa-pen-to-square fs-2 text-primary'></i>
              </a>
            </h5>
            <div className='card-toolbar'>
              <button type='button' className='btn btn-sm btn-primary' onClick={handleBackClick}>
                Back
              </button>
            </div>
          </div>
          <div className='closing_balance_wrapper p-5 bg-light-primary border-top-0 border-start-0 border-end-0 border-primary border-dashed'>
            <div className='px-5'>
              <div className='mb-3'>
                <label className='mb-3 text-gray-800'>CLOSING BALANCE</label>
                <h2 className='text-primary'>₹{accountBriefDetails?.account?.net_balance}</h2>
              </div>
              <div className=''>
                <label className=''>
                  Description : -- {accountBriefDetails?.account?.description}
                </label>
              </div>
            </div>
          </div>
          <div className='card-body'>
            <h4 className='mb-5'>Recent Transaction</h4>
            <div className='table-responsive'>
              <table className='table m-0 gs-7 gy-7 gx-7'>
                <thead>
                  <tr className='fw-bold fs-6 text-gray-800 border-top border-bottom border-gray-200.'>
                    <th className='ps-0'>Date</th>
                    <th>Transaction Details</th>
                    <th>Type</th>
                    <th>Transaction ID</th>
                    <th className='text-end'>Debit</th>
                    <th className='text-end pe-0'>Credit</th>
                  </tr>
                </thead>
                <tbody>
                  {accountBriefDetails?.account_transactions?.length > 0 ? (
                    accountBriefDetails?.account_transactions.map(
                      (transaction: any, index: number) => (
                        <tr key={index}>
                          <td className='ps-0'>
                            {moment(transaction?.account_journal?.journal_date).format(
                              'DD-MM-YYYY'
                            )}
                          </td>
                          <td>{transaction?.account_journal?.journal_details}</td>
                          <td className='text-capitalize'>
                            {transaction?.account_journal?.journal_entity_type.replace(/_/g, ' ')}
                          </td>
                          <td>{transaction?.account_journal?.journal_transaction_id}</td>

                          <td className='text-end'>
                            {transaction?.posting_type == 'debit'
                              ? '₹' + Number(transaction?.amount)?.toFixed(2)
                              : '-'}
                          </td>
                          <td className='text-end pe-0'>
                            {transaction?.posting_type == 'credit'
                              ? '₹' + Number(transaction?.amount)?.toFixed(2)
                              : '-'}
                          </td>
                        </tr>
                      )
                    )
                  ) : (
                    <td colSpan={6}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        <b>No transaction records found</b>
                      </div>
                    </td>
                  )}
                </tbody>
                {accountBriefDetails?.account_transactions?.length > 0 && (
                  <tfoot>
                    <Link to='' className='pt-5 fs-4' onClick={(e) => handleNavigate(e, account)}>
                      View More Information
                    </Link>
                  </tfoot>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AccountDetailsBrief
