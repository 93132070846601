import React, {FC, useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {PageTitle} from '../../../../../../_metronic/layout/core'
import * as Yup from 'yup'
import DatePicker from 'react-datepicker'
import {useFormik} from 'formik'
import {toast} from 'react-toastify'
import moment from 'moment'
import {AccountDetailFilter} from '../../../../manual-journal/manual-journal-list/core/_models'
import {getAccountFullDetails} from '../../core/_requests'
import {getCurrentFiscalYear} from '../../../../../../_metronic/helpers'

type AccountState = {
  account: any
}

const AccountDetailsFull = () => {
  const currentYear = getCurrentFiscalYear()
  const fiscalYearStart = new Date(`${currentYear}-04-01`)
  const today = new Date()
  const location = useLocation()
  const state = location.state as AccountState
  const {account} = state || {}
  const [startDate, setStartDate] = useState<Date | null>(fiscalYearStart)
  const [endDate, setEndDate] = useState<Date | null>(today)
  const [accountFullDetails, setAccountFullDetails] = useState<any>(null)
  const [debitTotalSum, setTotalDebitSum] = useState<any>(0)
  const [creditTotalSum, setTotalCreditSum] = useState<any>(0)
  const [closingBalanceAmount, setClosingBalanceAmount] = useState<any>(0)

  const editUserSchema = Yup.object().shape({
    start_date: Yup.string().required('Start Date required'),
    end_date: Yup.string().required('End Date required'),
  })

  const [userForEdit] = useState<any>({
    start_date: fiscalYearStart,
    end_date: today,
  })

  const handleBackClick = () => {
    window.history.back()
  }

  const fetchAndSetAccountDetails = async (
    accountId: string,
    dateRange: {startDate: string; endDate: string}
  ) => {
    try {
      const res = await getAccountFullDetails(accountId, dateRange)
      const accountFullDetailsResponse = res?.data?.data
      setAccountFullDetails(accountFullDetailsResponse)

      // Initialize credit and debit totals
      let totalCredit = 0
      let totalDebit = 0

      // Calculate total credit and debit sums
      accountFullDetailsResponse?.account_transactions?.forEach((transaction: any) => {
        const amount = parseFloat(transaction.amount)
        if (transaction.posting_type === 'credit') {
          totalCredit += amount
        } else if (transaction.posting_type === 'debit') {
          totalDebit += amount
        }
      })

      // Update state with calculated totals
      setTotalCreditSum(totalCredit)
      setTotalDebitSum(totalDebit)
      const debitCreditDiff = totalCredit - totalDebit
      setClosingBalanceAmount(accountFullDetailsResponse?.opening_balance + debitCreditDiff)
    } catch (ex: any) {
      if (ex.response.data.message) {
        toast.error(ex.response.data.message)
      }
    }
  }

  useEffect(() => {
    if (account) {
      const dateRange: any = {
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
      }
      fetchAndSetAccountDetails(account?._id, dateRange)
    }
  }, [account])

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)

      const dateRange: any = {
        startDate: moment(values?.start_date).format('YYYY-MM-DD'),
        endDate: moment(values?.end_date).format('YYYY-MM-DD'),
      }
      await fetchAndSetAccountDetails(account?._id, dateRange)
      setSubmitting(false)
    },
  })

  if (!account) {
    return <div>No account data found.</div>
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>Account Transactions </PageTitle>
      <div>
        <div className='card card-custom card-flush h-auto'>
          <div className='card-header pt-5'>
            <div className='card-toolbar row w-100 mb-3 gap-3 gap-lg-0'>
              <div className='col-12 col-lg-10 order-1 order-lg-0 ps-0'>
                <div className='account-details-filter'>
                  <div className=''>
                    <form onSubmit={formik.handleSubmit}>
                      <div className='row'>
                        <div className='fv-row col-12 col-md-3'>
                          <div className='position-relative'>
                            <div className='w-100'>
                              <DatePicker
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                {...formik.getFieldProps('start_date')}
                                dropdownMode='select'
                                className='form-control form-control-solid mb-3 mb-lg-0 cursor-pointer w-100'
                                dateFormat='dd-MM-yyyy'
                                name='start_date'
                                selected={startDate}
                                onChange={(date) => {
                                  formik.setFieldValue('start_date', date)
                                  setStartDate(date)
                                }}
                                autoComplete='off'
                                disabled={formik.isSubmitting}
                                onKeyDown={(e) => {
                                  e.preventDefault()
                                }}
                                placeholderText='Start Date'
                              />
                              <span
                                className='DatePicker_icon cursor-pointer'
                                onClick={() => document.getElementsByName('start_date')[0].focus()}
                              >
                                {/* <i className='fa fa-calendar'></i> */}
                              </span>
                            </div>
                          </div>
                          {formik.touched.start_date && formik.errors.start_date && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{String(formik.errors.start_date)}</span>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className='fv-row col-12 col-md-3'>
                          <div className='position-relative'>
                            <div className='w-100'>
                              <DatePicker
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                {...formik.getFieldProps('end_date')}
                                dropdownMode='select'
                                className='form-control form-control-solid mb-3 mb-lg-0 cursor-pointer w-100'
                                dateFormat='dd-MM-yyyy'
                                name='end_date'
                                selected={endDate}
                                onChange={(date) => {
                                  formik.setFieldValue('end_date', date)
                                  setEndDate(date)
                                }}
                                autoComplete='off'
                                disabled={formik.isSubmitting}
                                onKeyDown={(e) => {
                                  e.preventDefault()
                                }}
                                placeholderText='End Date'
                              />
                              <span
                                className='DatePicker_icon cursor-pointer'
                                onClick={() => document.getElementsByName('end_date')[0].focus()}
                              >
                                {/* <i className='fa fa-calendar'></i> */}
                              </span>
                            </div>
                          </div>
                          {formik.touched.end_date && formik.errors.end_date && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{String(formik.errors.end_date)}</span>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className='fv-row col-12 col-md-3'>
                          <button
                            type='submit'
                            className='btn btn-info'
                            data-kt-users-modal-action='submit'
                            disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                          >
                            <span className='indicator-label'>Apply</span>
                            {formik.isSubmitting && (
                              <span className='indicator-progress'>
                                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className='col-12 col-lg-2 order-0 order-lg-1 text-end'>
                <button type='button' className='btn btn-sm btn-primary' onClick={handleBackClick}>
                  Back
                </button>
              </div>
            </div>
            <div className='account_general_details w-100 d-flex justify-content-center text-center py-5'>
              <div className=''>
                <p className='m-0'>FreeStyle Infotech</p>
                <h3>Account Transactions</h3>
                <h5>{account?.account_name}</h5>
                <h6>
                  From {moment(startDate).format('DD/MM/YYYY')} to{' '}
                  {moment(endDate).format('DD/MM/YYYY')}
                </h6>
              </div>
            </div>
          </div>

          <div className='card-body'>
            <div className='table-responsive'>
              <table className='table m-0 gs-4 gy-4 gx-4'>
                <thead>
                  <tr className='fw-bold fs-6 text-gray-800 border-top border-bottom border-gray-200.'>
                    <th className='ps-0'>Date</th>
                    <></>
                    <th>Transaction Details</th>
                    <th>Type</th>
                    <th>Transaction ID</th>
                    <th className='text-end'>Debit</th>
                    <th className='text-end pe-0'>Credit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='ps-0'>As On {moment(startDate).format('DD/MM/YYYY')}</td>
                    <td>Opening Balance</td>
                    <td className='text-capitalize'></td>
                    <td></td>
                    <td className='text-end'>
                      {accountFullDetails?.opening_balance < 0 &&
                        '₹' + Math.abs(accountFullDetails?.opening_balance?.toFixed(2))}
                    </td>
                    <td className='text-end pe-0'>
                      {accountFullDetails?.opening_balance >= 0 &&
                        '₹' + accountFullDetails?.opening_balance?.toFixed(2)}
                    </td>
                  </tr>
                  {accountFullDetails?.account_transactions?.length > 0 &&
                    accountFullDetails?.account_transactions.map(
                      (transaction: any, index: number) => (
                        <tr key={index}>
                          <td className='ps-0'>
                            {transaction?.account_journal != null &&
                              moment(transaction?.account_journal?.journal_date).format(
                                'DD/MM/YYYY'
                              )}
                          </td>
                          <td>{transaction?.account_journal?.journal_details}</td>
                          <td className='text-capitalize'>
                            {transaction?.account_journal?.journal_entity_type.replace(/_/g, ' ')}
                          </td>
                          <td>{transaction?.account_journal?.journal_transaction_id}</td>
                          <td className='text-end'>
                            {transaction?.posting_type == 'debit'
                              ? Number(transaction?.amount)?.toFixed(2)
                              : '-'}
                          </td>
                          <td className='text-end pe-0'>
                            {transaction?.posting_type == 'credit'
                              ? Number(transaction?.amount)?.toFixed(2)
                              : '-'}
                          </td>
                        </tr>
                      )
                    )}
                  {accountFullDetails?.account_transactions?.length > 0 && (
                    <tr>
                      <td className='ps-0'></td>
                      <td>
                        Total Debit and Credit ({moment(startDate).format('DD/MM/YYYY')} -{' '}
                        {moment(endDate).format('DD/MM/YYYY')})
                      </td>
                      <td className='text-capitalize'></td>
                      <td></td>
                      <td className='text-end'>₹{debitTotalSum?.toFixed(2)}</td>
                      <td className='text-end pe-0'>₹{creditTotalSum.toFixed(2)}</td>
                    </tr>
                  )}

                  <tr>
                    <td className='ps-0'>As On {moment(endDate).format('DD/MM/YYYY')}</td>
                    <td>Closing Balance</td>
                    <td className='text-capitalize'></td>
                    <td></td>
                    <td className='text-end'>
                      {closingBalanceAmount < 0 && '₹' + Math.abs(closingBalanceAmount?.toFixed(2))}
                    </td>
                    <td className='text-end pe-0'>
                      {closingBalanceAmount >= 0 && '₹' + closingBalanceAmount?.toFixed(2)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AccountDetailsFull
