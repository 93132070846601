import axios, {AxiosResponse} from 'axios'
import {Account, AccountGroup, Accounts, UsersQueryResponse} from './_models'
import {ID, Response} from '../../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_API_URL
const USER_URL = `${API_URL}/user`
const GET_USERS_URL = `${API_URL}/users/query`
const GET_ACCOUNT_LIST_URL = `${API_URL}/account/list_group`
const GET_ACCOUNT_BRIEF_DETAILS_URL = `${API_URL}/account/account_brief_details`
const GET_ACCOUNT_FULL_DETAILS_URL = `${API_URL}/account/account_full_details`
const GET_PARENT_GROUP_URL = `${API_URL}/account/list_account_group`
const CREATE_ACCOUNT_URL = `${API_URL}/account/create_account`
const CREATE_ACCOUNT_GROUP_URL = `${API_URL}/account/create_account_group`

const getAccounts = (query: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_ACCOUNT_LIST_URL}?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getAccountBriefDetails = (accountId: string) => {
  return axios.get(`${GET_ACCOUNT_BRIEF_DETAILS_URL}/${accountId}`)
}
const getAccountFullDetails = (accountId: string, dateRange: any) => {
  return axios.get(
    `${GET_ACCOUNT_FULL_DETAILS_URL}/${accountId}?start_date=${dateRange?.startDate}&end_date=${dateRange?.endDate}`
  )
}

const getParentGroups = () => {
  return axios.get(`${GET_PARENT_GROUP_URL}`)
}

const getUserById = (id: ID): Promise<Accounts | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<Accounts>>) => response.data)
    .then((response: Response<Accounts>) => response.data)
}

const createAccount = (data: Account): Promise<Accounts | undefined> => {
  return axios.post(CREATE_ACCOUNT_URL, data)
}
const createAccountGroup = (data: AccountGroup): Promise<Accounts | undefined> => {
  return axios.post(CREATE_ACCOUNT_GROUP_URL, data)
}

const updateUser = (user: Accounts): Promise<Accounts | undefined> => {
  return axios
    .post(`${USER_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<Accounts>>) => response.data)
    .then((response: Response<Accounts>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getAccounts,
  getParentGroups,
  deleteUser,
  deleteSelectedUsers,
  getUserById,
  createAccount,
  createAccountGroup,
  updateUser,
  getAccountBriefDetails,
  getAccountFullDetails,
}
