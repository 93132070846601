import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {toast} from 'react-toastify'
import moment from 'moment'
import {initialUser, ManualJounal} from '../core/_models'
import {PageTitle} from '../../../../../_metronic/layout/core'
import DatePicker from 'react-datepicker'
import {KTSVG} from '../../../../../_metronic/helpers'

type Props = {
  isUserLoading: boolean
  user: ManualJounal
}

const ManualJournalForm: FC<any> = ({user, isUserLoading}) => {
  let editUserSchema
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const [journalDate, setJournalDate] = useState<Date | null>(null)
  const {refetch} = useQueryResponse()

  const [items, setItems] = useState([{account: '', description: '', debit: '', credit: ''}])
  const [fieldErrors, setFieldErrors] = useState<{[key: string]: string}>({})
  const [debitDifference, setDebitDifference] = useState('')
  const [creditDifference, setCreditDifference] = useState('')
  const [subTotalDebit, setSubTotalDebit] = useState('')
  const [subTotalCredit, setSubTotalCredit] = useState('')

  const [userForEdit] = useState<ManualJounal>({
    ...user,
    journal_title: user?.journal_title || initialUser.journal_title,
    journal_number: user?.journal_number || initialUser.journal_number,
    journal_notes: user?.journal_notes || initialUser.journal_notes,
    journal_reference_number:
      user?.journal_reference_number || initialUser.journal_reference_number,
    journal_date: moment(user?.journal_date).format('DD-MM-YYYY'),
  })

  editUserSchema = Yup.object().shape({
    journal_title: Yup.string().required('Journal title is required'),
    journal_number: Yup.string().required('Journal number is required'),
    journal_notes: Yup.string().required('Journal notes is required'),
    journal_date: Yup.string().required('Journal Date required'),
    journal_reference_number: Yup.string().required('Journal reference number is required'),
  })

  const cancel = (withRefresh?: boolean) => {
    window.history.back()
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      const isvalid = validateItems()

      if (isvalid) {
        setSubmitting(true)
        const abc = {...values, items}

        try {
          // Calculate total billing cost and total payable amount
          // Add the total billing cost and total payable amount to the form values
          // values.total_billing_cost = totalBillingCost
          // values.total_payable_amount = totalPayableAmount2
          // values.billing_date = moment(currentDate).format('DD-MM-YYYY HH:mm')
          // if (isNotEmpty(values?.bill_number)) {
          //   await updatePatientBiling(values).then((res: any) => {
          //     toast.success(res?.data?.message)
          //     navigate('/patient-data/patient-billing')
          //   })
          // } else {
          //   await createPatientBiling({
          //     ...values,
          //     patient_id: Number(idSelectedPatient ? idSelectedPatient : selectedPatientId),
          //     hospital_id: Number(selectedHospitalId),
          //     billing_detail: items,
          //   }).then((res: any) => {
          //     toast.success(res?.data?.message)
          //     navigate('/patient-data/patient-billing')
          //   })
          // }
        } catch (ex: any) {
          if (ex.response.data.message) {
            toast.error(ex.response.data.message)
          }
        } finally {
          setSubmitting(true)
        }
      }
    },
  })

  const addNewItem = () => {
    const newItem = {
      account: '',
      description: '',
      debit: '',
      credit: '',
    }

    const updatedItems = [...items, newItem]
    setItems(updatedItems)
  }

  const removeItem = (index: number) => {
    const updatedItems = [...items]
    updatedItems.splice(index, 1)
    setItems(updatedItems)
    calculateDebitSubtotal(updatedItems)
    calculateCreditSubtotal(updatedItems)
  }

  const handleBackClick = () => {
    window.history.back()
  }

  const hasErrors = (): boolean => {
    return Object.values(fieldErrors).some((error) => error !== '')
  }

  const validateItems = () => {
    const updatedItems = [...items]
    for (let index = 0; index < updatedItems.length; index++) {
      const element = updatedItems[index]
      if (!element.description || !element.account) {
        if (!element.description) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [`items[${index}].description`]: 'Description is required',
          }))
        }
        if (!element.account) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [`items[${index}].account`]: 'Accoumnt is required',
          }))
        }
        return false
      } else {
        return true
      }
    }
  }

  const calculateDebitSubtotal = (itemsToCalculate = items) => {
    let totalDebit = 0
    let totalCredit = 0
    itemsToCalculate.forEach((item) => {
      if (item.debit) {
        totalDebit += parseFloat(item.debit)
      }
      if (item.credit) {
        totalCredit += parseFloat(item.credit)
      }
    })
    setSubTotalDebit(totalDebit.toFixed(2))
    setSubTotalCredit(totalCredit.toFixed(2))
    calculateDifferences(totalDebit, totalCredit)
  }

  const calculateCreditSubtotal = (itemsToCalculate = items) => {
    let totalCredit = 0
    let totalDebit = 0
    itemsToCalculate.forEach((item) => {
      if (item.credit) {
        totalCredit += parseFloat(item.credit)
      }
      if (item.debit) {
        totalDebit += parseFloat(item.debit)
      }
    })
    setSubTotalCredit(totalCredit.toFixed(2))
    setSubTotalDebit(totalDebit.toFixed(2))
    calculateDifferences(totalDebit, totalCredit)
  }

  const calculateDifferences = (debit: number, credit: number) => {
    const difference = Math.abs(debit - credit).toFixed(2)
    if (debit > credit) {
      setDebitDifference(difference)
      setCreditDifference('')
    } else if (credit > debit) {
      setCreditDifference(difference)
      setDebitDifference('')
    } else {
      setDebitDifference('')
      setCreditDifference('')
    }
  }

  return (
    <>
      <div>
        <PageTitle breadcrumbs={[]}>Manual Journal</PageTitle>
        <div>
          <div className='card card-custom card-flush h-auto'>
            <div className='card-header justify-content-end'>
              {/* <h3 className='card-title' style={{fontSize: '30px'}}>
                {billData?.bill_number ? `Invoice # ${billData?.bill_number}` : ''}
              </h3> */}
              <div className='card-toolbar'>
                <button type='button' className='btn btn-sm btn-primary' onClick={handleBackClick}>
                  Back
                </button>
              </div>
            </div>
            <div className='card-body py-5'>
              <form
                id='kt_modal_add_user_form3'
                className='form'
                onSubmit={formik.handleSubmit}
                noValidate
              >
                <div
                  className='d-flex flex-column scroll-y me-n7 pe-7'
                  id='kt_modal_add_user_scroll'
                >
                  <div className='row'>
                    <div className='fv-row mb-7 col-4'>
                      <label className='required fw-bold fs-6 mb-2'>Journal Title</label>
                      <input
                        placeholder='Enter Journal Title'
                        {...formik.getFieldProps('journal_title')}
                        type='text'
                        name='journal_title'
                        className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                        autoComplete='off'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.journal_title && formik.errors.journal_title && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.journal_title}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='fv-row mb-7 col-4'>
                      <label className='required fw-bold fs-6 mb-2'>Journal Number</label>
                      <input
                        placeholder='Enter Journal Number'
                        {...formik.getFieldProps('journal_number')}
                        type='text'
                        name='journal_number'
                        className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                        autoComplete='off'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.journal_number && formik.errors.journal_number && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.journal_number}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='fv-row mb-7 col-4'>
                      <label className='required fw-bold fs-6 mb-2'>Reference Number</label>
                      <input
                        placeholder='Enter Reference Number'
                        {...formik.getFieldProps('journal_reference_number')}
                        type='text'
                        name='journal_reference_number'
                        className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                        autoComplete='off'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.journal_reference_number &&
                        formik.errors.journal_reference_number && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.journal_reference_number}</span>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>

                  <div className='row'>
                    <div className='fv-row mb-7 col-4'>
                      <label className={`fw-bold fs-6 mb-2`}>Jornal Date</label>
                      <div className='position-relative'>
                        <div className='w-100'>
                          <DatePicker
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            {...formik.getFieldProps('journal_date')}
                            dropdownMode='select'
                            className='form-control form-control-solid  mb-3 mb-lg-0 cursor-pointer w-100'
                            dateFormat='dd-MM-yyyy'
                            name='journal_date'
                            selected={journalDate}
                            onChange={(date) => {
                              formik.setFieldValue('journal_date', date)
                              setJournalDate(date)
                            }}
                            autoComplete='off'
                            disabled={formik.isSubmitting || isUserLoading}
                            onKeyDown={(e) => {
                              e.preventDefault()
                            }}
                            placeholderText='Journal Date'
                          />
                          <span
                            className='DatePicker_icon cursor-pointer'
                            onClick={() => document.getElementsByName('journal_date')[0].focus()}
                          >
                            {/* <i className='fa fa-calendar'></i> */}
                          </span>
                        </div>
                      </div>
                      {formik.touched.journal_date && formik.errors.journal_date && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.journal_date}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='fv-row mb-7 col-4'>
                      <label className='fw-bold fs-6 mb-2'>Notes</label>
                      <textarea
                        rows={3}
                        cols={50}
                        style={{resize: 'none'}}
                        placeholder='Enter Notes'
                        {...formik.getFieldProps('journal_notes')}
                        className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                        name='journal_notes'
                        autoComplete='off'
                        disabled={formik.isSubmitting || isUserLoading}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.journal_notes && formik.errors.journal_notes && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.journal_notes}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='d-flex'>
                    <table className='table'>
                      <thead>
                        <tr className='row fw-bold fs-6'>
                          <th className='col-3 required ps-3'>Account</th>
                          <th className='col-4 required'>Description</th>
                          <th className='col-2'>Debit</th>
                          <th className='col-2'>Credit</th>
                        </tr>
                      </thead>
                      <tbody>
                        {items &&
                          items?.map((item: any, index: number) => (
                            <tr key={index} className='row'>
                              <td className='col-3 ps-3'>
                                <select
                                  className='form-select'
                                  aria-label='Select example'
                                  {...formik.getFieldProps('account')}
                                  name='account'
                                  onChange={(e) => {
                                    const updatedItems = [...items]
                                    updatedItems[index].account = e.target.value
                                    setItems(updatedItems)

                                    if (!updatedItems[index].account) {
                                      setFieldErrors((prevErrors) => ({
                                        ...prevErrors,
                                        [`items[${index}].account`]: 'Account is required',
                                      }))
                                    } else {
                                      setFieldErrors((prevErrors) => ({
                                        ...prevErrors,
                                        [`items[${index}].account`]: '',
                                      }))
                                    }
                                  }}
                                  onBlur={() => {
                                    if (!items[index].account) {
                                      setFieldErrors((prevErrors) => ({
                                        ...prevErrors,
                                        [`items[${index}].account`]: 'Account is required',
                                      }))
                                    } else {
                                      setFieldErrors((prevErrors) => ({
                                        ...prevErrors,
                                        [`items[${index}].account`]: '',
                                      }))
                                    }
                                  }}
                                >
                                  <option value=''>Select Account</option>
                                  <option value='1'>Kishan</option>
                                  <option value='2'>Dhruvil</option>
                                  <option value='3'>Jay</option>
                                </select>
                                {fieldErrors[`items[${index}].account`] && !item.account && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      <span>{fieldErrors[`items[${index}].account`]}</span>
                                    </div>
                                  </div>
                                )}
                              </td>
                              <td className='col-4'>
                                <input
                                  placeholder='Enter Description'
                                  value={item.description}
                                  type='text'
                                  required
                                  name={`items[${index}].description`}
                                  className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                                  autoComplete='off'
                                  onChange={(e) => {
                                    const updatedItems = [...items]
                                    updatedItems[index].description = e.target.value
                                    setItems(updatedItems)

                                    if (!items[index].description) {
                                      setFieldErrors((prevErrors) => ({
                                        ...prevErrors,
                                        [`items[${index}].description`]: 'Description is required',
                                      }))
                                    } else {
                                      setFieldErrors((prevErrors) => ({
                                        ...prevErrors,
                                        [`items[${index}].description`]: '',
                                      }))
                                    }
                                  }}
                                  onBlur={(e) => {
                                    if (!items[index].description) {
                                      setFieldErrors((prevErrors) => ({
                                        ...prevErrors,
                                        [`items[${index}].description`]: 'Description is required',
                                      }))
                                    } else {
                                      setFieldErrors((prevErrors) => ({
                                        ...prevErrors,
                                        [`items[${index}].description`]: '',
                                      }))
                                    }
                                  }}
                                />
                                {fieldErrors[`items[${index}].description`] && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      <span>{fieldErrors[`items[${index}].description`]}</span>
                                    </div>
                                  </div>
                                )}
                              </td>
                              <td className='col-2'>
                                <input
                                  placeholder='Debit Amount'
                                  value={item.debit}
                                  type='number'
                                  name={`items[${index}].debit`}
                                  className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                                  autoComplete='off'
                                  onChange={(e) => {
                                    const updatedItems = [...items]
                                    updatedItems[index].debit = e.target.value
                                    setItems(updatedItems)
                                    calculateDebitSubtotal()
                                  }}
                                  onBlur={(e) => {
                                    const updatedItems = [...items]
                                    if (
                                      updatedItems[index].credit.length > 0 &&
                                      e.target.value.length > 0
                                    ) {
                                      updatedItems[index].credit = ''
                                      setItems(updatedItems)
                                    } else {
                                      updatedItems[index].debit = e.target.value
                                      setItems(updatedItems)
                                    }
                                    calculateCreditSubtotal()
                                  }}
                                />
                                {/* {fieldErrors[`items[${index}].debit`] && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      <span>{fieldErrors[`items[${index}].debit`]}</span>
                                    </div>
                                  </div>
                                )} */}
                              </td>
                              <td className='col-2'>
                                <input
                                  placeholder='Credit Amount'
                                  value={item.credit}
                                  type='number'
                                  name={`items[${index}].credit`}
                                  className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                                  autoComplete='off'
                                  onChange={(e) => {
                                    const updatedItems = [...items]
                                    updatedItems[index].credit = e.target.value
                                    setItems(updatedItems)
                                    calculateCreditSubtotal()
                                  }}
                                  onBlur={(e) => {
                                    const updatedItems = [...items]
                                    if (
                                      updatedItems[index].debit.length > 0 &&
                                      e.target.value.length > 0
                                    ) {
                                      updatedItems[index].debit = ''
                                      setItems(updatedItems)
                                    } else {
                                      updatedItems[index].credit = e.target.value
                                      setItems(updatedItems)
                                    }
                                    calculateDebitSubtotal()
                                  }}
                                />
                                {/* {fieldErrors[`items[${index}].credit`] && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      <span>{fieldErrors[`items[${index}].credit`]}</span>
                                    </div>
                                  </div>
                                )} */}
                              </td>
                              <td className='col-1 text-center'>
                                <div
                                  className='costperhead_delete btn btn-light-danger btn-active-danger btn-sm px-4 py-3'
                                  style={{minHeight: '43px'}}
                                  onClick={() => removeItem(index)}
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/general/gen027.svg'
                                    className='svg-icon-muted m-0 fs-3'
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>

                  <div className='row mb-5'>
                    <div className='col-3'>
                      <div className=''>
                        <button type='button' className='btn btn-success' onClick={addNewItem}>
                          Add Item
                        </button>
                      </div>
                    </div>
                    <div className='col-9'>
                      <div className='py-5 bg-warning-subtle'>
                        <div className='row mb-5 align-items-center'>
                          <div className='col-5 text-center'>
                            <h2 className='fw-bold mb-0'>Sub Total</h2>
                          </div>
                          <div className='col-6'>
                            <div className='row align-items-center'>
                              <div className='col-1'></div>
                              <div className='col-5 ps-2 pe-2'>
                                <input
                                  {...formik.getFieldProps('sub_total_debit')}
                                  value={subTotalDebit || '0.0'}
                                  readOnly
                                  type='text'
                                  name='sub_total'
                                  className='w-100 bg-transparent border-0 fs-2'
                                  disabled
                                />
                              </div>
                              <div className='col-5 ps-2 pe-2'>
                                <input
                                  {...formik.getFieldProps('sub_total_credit')}
                                  value={subTotalCredit || '0.0'}
                                  readOnly
                                  type='text'
                                  name='sub_total_credit'
                                  className='w-100 bg-transparent border-0 fs-2'
                                  disabled
                                />
                              </div>
                              <div className='col-1'></div>
                            </div>
                          </div>
                          <div className='col-1'></div>
                        </div>
                        <div className='row align-items-center'>
                          <div className='col-5 text-center'>
                            <h4 className='fw-bold fs-6 mb-0 text-danger'>Difference</h4>
                          </div>
                          <div className='col-6'>
                            <div className='row align-items-center'>
                              <div className='col-1'></div>
                              <div className='col-5 ps-2 pe-2'>
                                <input
                                  {...formik.getFieldProps('debit_difference')}
                                  value={debitDifference}
                                  readOnly
                                  type='text'
                                  name='debit_difference'
                                  className='w-100 bg-transparent border-0 fs-4 text-danger'
                                  disabled
                                />
                              </div>
                              <div className='col-5 ps-2 pe-2'>
                                <input
                                  {...formik.getFieldProps('credit_difference')}
                                  value={creditDifference || '0.0'}
                                  readOnly
                                  type='text'
                                  name='credit_difference'
                                  className='w-100 bg-transparent border-0 fs-4 text-danger'
                                  disabled
                                />
                              </div>
                              <div className='col-1'></div>
                            </div>
                          </div>
                          <div className='col-1'></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='text-center pt-15'>
                  <button
                    type='reset'
                    onClick={() => cancel()}
                    className='btn btn-light me-3'
                    data-kt-users-modal-action='cancel'
                    disabled={formik.isSubmitting || isUserLoading}
                  >
                    Close
                  </button>

                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    disabled={formik.isSubmitting || hasErrors()}
                  >
                    <span className='indicator-label'>Save</span>
                    {(formik.isSubmitting || isUserLoading) && (
                      <span className='indicator-progress'>
                        Please wait
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
              {/* {(formik.isSubmitting || isUserLoading) && <PatientBillingListLoading />} */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {ManualJournalForm}
