import React from 'react'
import {IoIosMailOpen} from 'react-icons/io'
import {IoCallSharp} from 'react-icons/io5'
import {MdLocationOn} from 'react-icons/md'
import {Link} from 'react-router-dom'

const LandingFooter = () => {
  return (
    <section className='footer landing_footer section-padding pb-0'>
      <div className='container'>
        <div className='row'>
          <div
            className='col-12 col-lg-4 mb-4 mb-sm-5 mb-lg-0'
            data-aos='fade-up'
            data-aos-duration='500'
          >
            <h3>FreeStyleFNO</h3>
            <p className='my-3 footer-caption-text'>One step closure to success</p>
          </div>
          <div
            className='col-12 col-lg-3 footer-link-block 4 mb-4 mb-sm-5 mb-lg-0'
            data-aos='fade-up'
            data-aos-duration='1000'
          >
            <h4 className='mb-3 mb-sm-4 footer-head'>Explore</h4>
            <ul className='footer-ul text-start'>
              <li>
                <Link to='/privacy-policy'>Privacy Policy</Link>
              </li>
              <li>
                <Link to='/terms-and-conditions'>Terms & Conditions</Link>
              </li>
              <li>
                <Link to='/refund-policy'>Refund Policy</Link>
              </li>
            </ul>
          </div>
          <div
            className='col-12 col-lg-5 footer-contact-block'
            data-aos='fade-up'
            data-aos-duration='1500'
          >
            <h4 className='mb-3 mb-sm-4 footer-head'>Contact</h4>
            <ul className='footer-ul'>
              <li>
                <span>
                  <MdLocationOn />
                </span>
                <Link className='ms-2 ms-sm-3' to='/'>
                  C/o PATEL MUKESHBHAI NARANBHAI, SAIVILA RESIDENCY,BAYAD, Bayad, Bayad,Sabarkantha,
                  Gujarat, ,Himatnagar ,India
                </Link>
              </li>
              <li>
                <span>
                  <IoCallSharp />
                </span>
                <Link className='ms-2 ms-sm-3' to='tel:+09913799324' target='_blank'>
                  +91 9979688595
                </Link>
              </li>
              <li>
                <span>
                  <IoIosMailOpen />
                </span>
                <Link
                  className='ms-2 ms-sm-3'
                  to='mailto:info@freestyleinfotech.com'
                  target='_blank'
                >
                  info@freestyleinfotech.com
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <div className='row py-4 px-1 text-center'>
          <div className='col-12'>
            <p className='copyright-text'>
              All Rights Reserved &copy; {new Date().getFullYear()}&nbsp; by FreeStyleFNO
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LandingFooter
