import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const GET_CLIENTS_URL = `${API_URL}/admin/clients`

const getClients = () => {
  return axios.get(`${GET_CLIENTS_URL}`)
}

export {getClients}
