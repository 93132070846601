import React from 'react'
import {Link} from 'react-router-dom'
import {RxHamburgerMenu} from 'react-icons/rx'
import {MdLocationOn} from 'react-icons/md'
import {IoCallSharp} from 'react-icons/io5'
import {IoIosMailOpen} from 'react-icons/io'
import LandingLayout from './LandingLayout'

const LandingPage = () => {
  return (
    <>
      {/* <div className='d-flex justify-content-center align-items-center h-100'>
        <div className='text-center'>
          <h1 style={{fontSize: '40px'}}>FreeStyleFNO</h1>
          <h4 style={{fontSize: '60px'}}>Coming Soon...</h4>
        </div>
      </div> */}
      <div className='landing_main'>
        <LandingLayout>
          <div className='landing_body'>
            <section className='landing_banner'>
              <div className='hero-content text-white text-center '>
                <h1 className='hero_section_title text-capitalize mb-5'>Welcome to FreeStyleFNO</h1>
                <h6 className='mb-4'>With us one step closure to success</h6>
              </div>
            </section>
            <section className='our-plans section-padding'>
              <div className='container'>
                <div className='section_heading'>
                  <p className='divider_hr'>
                    <span></span>
                  </p>
                  <h2 className='section_title'>Our Plans</h2>
                </div>
                <div className='row justify-content-center justify-content-lg-start'>
                  <div
                    className='col-12 col-sm-6 col-lg-4 mb-4'
                    data-aos='fade-up'
                    data-aos-duration='500'
                  >
                    <div className='our-plan-card card h-100'>
                      <div className='card-body'>
                        <div className=''>
                          <h2 className='card-title fw-semibold'>Consultation</h2>
                          <p className='divider_hr'>
                            <span></span>
                          </p>
                          <ul className='fw-normal fs-4'>
                            <li className='mb-3'>In-depth IT strategy and planning</li>
                            <li className='mb-3'>Expert advice on technology implementation</li>
                            <li className='mb-3'>Customized solutions to meet business needs</li>
                            <li className='mb-3'>Ongoing support and guidance</li>
                          </ul>
                          <div className='price-tag badge badge-danger text-center w-100 justify-content-center mb-4'>
                            <p className='m-0 p-2 fs-4'>₹500/hr</p>
                            <p className='m-0 p-2 fs-4'>
                              <del>₹800/hr</del>
                            </p>
                          </div>
                          <div className='text-center'>
                            <a href='/login.php' className='btn btn-primary'>
                              Buy Now
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className='col-12 col-sm-6 col-lg-4 mb-4'
                    data-aos='fade-up'
                    data-aos-duration='500'
                  >
                    <div className='our-service-card card h-100'>
                      <div className='card-body'>
                        <div className=''>
                          <h2 className='card-title fw-semibold'>Web Development</h2>
                          <p className='divider_hr'>
                            <span></span>
                          </p>
                          <ul className='fw-normal fs-4'>
                            <li className='mb-3'>Responsive and dynamic website design</li>
                            <li className='mb-3'>E-commerce solutions and platforms</li>
                            <li className='mb-3'>SEO optimization for better visibility</li>
                            <li className='mb-3'>Maintenance and scalability</li>
                          </ul>
                          <div className='price-tag badge badge-danger text-center w-100 justify-content-center mb-4'>
                            <p className='m-0 p-2 fs-4'>₹1000/hr</p>
                            <p className='m-0 p-2 fs-4'>
                              <del>₹1400/hr</del>
                            </p>
                          </div>
                          <div className='text-center'>
                            <a href='/login.php' className='btn btn-primary'>
                              Buy Now
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className='col-12 col-sm-6 col-lg-4 mb-4'
                    data-aos='fade-up'
                    data-aos-duration='500'
                  >
                    <div className='our-service-card card h-100'>
                      <div className='card-body'>
                        <div className=''>
                          <h2 className='card-title fw-semibold'>App Developmemnt</h2>
                          <p className='divider_hr'>
                            <span></span>
                          </p>
                          <ul className='fw-normal fs-4'>
                            <li className='mb-3'>Custom mobile and web app development</li>
                            <li className='mb-3'>User-friendly interface design</li>
                            <li className='mb-3'>Integration with existing systems</li>
                            <li className='mb-3'>Support and maintenance services</li>
                          </ul>
                          <div className='price-tag badge badge-danger text-center w-100 justify-content-center mb-4'>
                            <p className='m-0 p-2 fs-4'>₹1200/hr</p>
                            <p className='m-0 p-2 fs-4'>
                              <del>₹1700/hr</del>
                            </p>
                          </div>
                          <div className='text-center'>
                            <a href='/login.php' className='btn btn-primary'>
                              Buy Now
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </LandingLayout>
      </div>
    </>
  )
}

export default LandingPage
