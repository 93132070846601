/* eslint-disable react/jsx-no-target-blank */
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/gen025.svg'
        title='Dashboard'
      />
      <AsideMenuItem to='/clients' icon='/media/icons/duotune/general/gen049.svg' title='Clients' />
      <AsideMenuItem to='/trades' icon='/media/icons/duotune/general/gen032.svg' title='Trades' />
      <AsideMenuItem
        to='/positions'
        icon='/media/icons/duotune/general/gen002.svg'
        title='Positions'
      />
      <AsideMenuItem
        to='/latest-demat-orders'
        icon='/media/icons/duotune/communication/com004.svg'
        title='Demat Orders'
      />
      <AsideMenuItem to='/logs' icon='/media/icons/duotune/general/gen010.svg' title='Logs' />
      <AsideMenuItem
        to='/instruments'
        icon='/media/icons/duotune/general/gen009.svg'
        title='Instruments'
      />
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/accountant'
        icon='/media/icons/duotune/general/gen067.svg'
        title='Accountant'
      >
        <AsideMenuItem to='accountant/accounts/' title='Accounts' hasBullet={true} />
        <AsideMenuItem to='accountant/manual-journal/' title='Manual Journal' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub to='/sales' icon='/media/icons/duotune/custom/ecm009.svg' title='Sales'>
        <AsideMenuItem to='sales/customers' title='Customers' hasBullet={true} />
        <AsideMenuItem to='sales/invoices' title='Invoices' hasBullet={true} />
        <AsideMenuItem to='sales/payment-received' title='Payment Recieved' hasBullet={true} />
        <AsideMenuItem to='sales/credit-notes' title='Credit Notes' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/purchase'
        icon='/media/icons/duotune/finance/fin006.svg'
        title='Purchase'
      >
        <AsideMenuItem to='purchase/vendors' title='Vendors' hasBullet={true} />
        <AsideMenuItem to='purchase/expense' title='Expense ' hasBullet={true} />
        <AsideMenuItem to='purchase/bills' title='Bills' hasBullet={true} />
        <AsideMenuItem to='purchase/payment-made' title='Payment Made' hasBullet={true} />
        <AsideMenuItem to='purchase/debit-note' title='Debit Note' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <AsideMenuItem
        to='/settings'
        icon='/media/icons/duotune/coding/cod001.svg'
        title='Settings'
      />
    </>
  )
}
