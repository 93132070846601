import {ID, Response} from '../../../../../_metronic/helpers'

export type ManualJounal = {
  id?: ID
  _id?: string
  journal_title?: string
  journal_number?: string
  journal_date?: string
  journal_notes?: string
  journal_reference_number?: string
  items: Item[]
}

export type AccountDetailFilter = {
  start_date?: string
  end_date?: string
}
export type Item = {
  account: string
  description: string
  debit: string
  credit: string
}

export type UsersQueryResponse = Response<Array<ManualJounal>>

export const initialUser: ManualJounal = {
  journal_title: '',
  journal_number: '',
  journal_date: '',
  journal_notes: '',
  journal_reference_number: '',
  items: [],
}

export const intialFilter: AccountDetailFilter = {
  start_date: '',
  end_date: '',
}
