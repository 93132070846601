import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {ManualJournalForm} from '../pages/manual-journal/manual-journal-list/manual-journal-edit-pages/ManualJournalForm'
import ClientTrades from '../pages/client-management/client-list/client-trades/ClientTrades'
import AccountDetailsFull from '../pages/accounts-management/accounts-list/components/account-details/AccountDetailsFull'
import AccountDetailsBrief from '../pages/accounts-management/accounts-list/components/account-details/AccountDetailsBrief'

const PrivateRoutes = () => {
  const AccountsPage = lazy(() => import('../pages/accounts-management/AccountsPage'))
  const LogsPage = lazy(() => import('../pages/logger/LoggerPage'))
  const ClientsPage = lazy(() => import('../pages/client-management/ClientsPage'))
  const TradesPage = lazy(() => import('../pages/trade-management/TradesPage'))
  const InstrumentPage = lazy(() => import('../pages/instrument-management/InstrumentPage'))
  const ManualJournalPage = lazy(() => import('../pages/manual-journal/ManualJournalPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const CustomerPage = lazy(() => import('../pages/customers/CustomerPage'))
  const InvoicePage = lazy(() => import('../pages/invoices/InvoicePage'))
  const PaymentReceivedPage = lazy(() => import('../pages/payment-received/PaymentReceivedPage'))
  const CreditNotedPage = lazy(() => import('../pages/credit-notes/CreditNotePage'))
  const VendorsPage = lazy(() => import('../pages/vendors/VendorPage'))
  const ExpensePage = lazy(() => import('../pages/expense-management/ExpensePage'))
  const BillsPage = lazy(() => import('../pages/bill-management/BillPage'))
  const PaymentMadePage = lazy(() => import('../pages/payments-made/PaymentMadePage'))
  const DebitNotePage = lazy(() => import('../pages/debit-notes/DebitNotePage'))
  const SettingsPage = lazy(() => import('../pages/settings/SettingsPageWrapper'))
  const PositionPage = lazy(() => import('../pages/positions/PositionPage'))
  const LatestDematOrdersPage = lazy(
    () => import('../pages/latest-demat-orders/LatestDematOrderPage')
  )

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='login' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        {/* Lazy Modules */}
        <Route
          path='clients'
          element={
            <SuspensedView>
              <ClientsPage />
            </SuspensedView>
          }
        />
        <Route
          path='client-trade'
          element={
            <SuspensedView>
              <ClientTrades />
            </SuspensedView>
          }
        />

        <Route
          path='trades'
          element={
            <SuspensedView>
              <TradesPage />
            </SuspensedView>
          }
        />
        <Route
          path='positions'
          element={
            <SuspensedView>
              <PositionPage />
            </SuspensedView>
          }
        />
        <Route
          path='latest-demat-orders'
          element={
            <SuspensedView>
              <LatestDematOrdersPage />
            </SuspensedView>
          }
        />
        <Route
          path='logs'
          element={
            <SuspensedView>
              <LogsPage />
            </SuspensedView>
          }
        />
        <Route
          path='instruments'
          element={
            <SuspensedView>
              <InstrumentPage />
            </SuspensedView>
          }
        />
        {/* Account Routes */}
        <Route
          path='accountant/accounts'
          element={
            <SuspensedView>
              <AccountsPage />
            </SuspensedView>
          }
        />
        <Route
          path='accountant/accounts/account-details-brief'
          element={
            <SuspensedView>
              <AccountDetailsBrief />
            </SuspensedView>
          }
        />
        <Route
          path='accountant/accounts/account-details-brief/account-details-full'
          element={
            <SuspensedView>
              <AccountDetailsFull />
            </SuspensedView>
          }
        />
        <Route
          path='accountant/manual-journal'
          element={
            <SuspensedView>
              <ManualJournalPage />
            </SuspensedView>
          }
        />
        <Route
          path='accountant/manual-journal/manual-journal-form'
          element={
            <SuspensedView>
              <ManualJournalForm />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        {/* Sales Routes */}
        <Route
          path='sales/customers'
          element={
            <SuspensedView>
              <CustomerPage />
            </SuspensedView>
          }
        />
        <Route
          path='sales/invoices'
          element={
            <SuspensedView>
              <InvoicePage />
            </SuspensedView>
          }
        />
        <Route
          path='sales/payment-received'
          element={
            <SuspensedView>
              <PaymentReceivedPage />
            </SuspensedView>
          }
        />
        <Route
          path='sales/credit-notes'
          element={
            <SuspensedView>
              <CreditNotedPage />
            </SuspensedView>
          }
        />
        {/* Purchase Routes */}
        <Route
          path='purchase/vendors'
          element={
            <SuspensedView>
              <VendorsPage />
            </SuspensedView>
          }
        />
        <Route
          path='purchase/expense'
          element={
            <SuspensedView>
              <ExpensePage />
            </SuspensedView>
          }
        />
        <Route
          path='purchase/bills'
          element={
            <SuspensedView>
              <BillsPage />
            </SuspensedView>
          }
        />
        <Route
          path='purchase/payment-made'
          element={
            <SuspensedView>
              <PaymentMadePage />
            </SuspensedView>
          }
        />
        <Route
          path='purchase/debit-note'
          element={
            <SuspensedView>
              <DebitNotePage />
            </SuspensedView>
          }
        />

        <Route
          path='settings'
          element={
            <SuspensedView>
              <SettingsPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
