import React from 'react'
import {RxHamburgerMenu} from 'react-icons/rx'
import {Link} from 'react-router-dom'

const LandingHeader = () => {
  return (
    <div className='landing_header'>
      <nav className={`navbar navbar-expand-lg `}>
        <div className='container-fluid'>
          <Link className='navbar-brand p-0' to='/'>
            <h3 className='m-0'>FreeStyleFNO</h3>
            {/* <span className='d-inline-block'>
                <img src={siteogo} className='site_logo' alt='Site Logo' />
              </span> */}
          </Link>
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarNav'
            aria-controls='navbarNav'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggle-custom'>
              <RxHamburgerMenu />
            </span>
          </button>

          <div className='collapse navbar-collapse' id='navbarNav'>
            <ul className='navbar-nav ms-auto mt-3 pt-3 mt-lg-0 pt-lg-0'>
              <li className='nav-item'>
                <a href='/login.php' className={`nav-link fs-4`} aria-current='page'>
                  Login
                </a>
              </li>
              <li className='nav-item'>
                <a href='/register.php' className={`nav-link fs-4`}>
                  Register
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default LandingHeader
