import React from 'react'

const UpstoxSetupBlog = () => {
  return (
    <div className='container-fluid p-5'>
      <div className='blog_header mb-5'>
        <h1>Below are the details how to link your Upstox demat account with FreeStyle FNO</h1>
      </div>
      <div className='blog_body'>
        <div>
          <div>
            <h2 className='mb-4 text-uppercase text-info'>How to add Demat Account ?</h2>
            <div>
              <h3>Step 1 : Go To Demat Account Section</h3>
              <ul>
                <li>Open FreeStyleFno App</li>
                <li>Go To Demat Account Section</li>
              </ul>
            </div>
            <div>
              <h3>Step 2 : Click on Add Broker Button (Plus icon)</h3>
              <p>Select the Upstox option using Upstox icon</p>
            </div>
            <div>
              <h3>Step 3 : Nick name</h3>
              <p>Enter the name of the demat account holder.</p>
            </div>
            <div>
              <h3>Step 4 : User ID</h3>
              <p>You can get the User ID by following below steps</p>
              <ul>
                <li>Open your upstox app</li>
                <li>Go to Account section</li>
                <li>Here you can find User ID Below Your Name : example - SFD4D3</li>
              </ul>
            </div>
            <div>
              <h3>Step 5 : API Key & API Secret key</h3>
              <p>You can get the API Key by following below steps</p>
              <ul>
                <li>Open Your Upstox App</li>
                <li>Go To Account Section</li>
                <li>Go To Profile Section and select My account</li>
                <li>Click on Apps and then click on New app</li>
                <li>Click on Create an App button</li>
                <li>In App Name enter any name : ex (FNO)</li>
                <li>
                  Enter the Redirect URL by copying the link from the FreeStyleFno App. (You can
                  find out in demat account section - where you are currently filling the
                  information) You can just click Tap to Copy button and paste url in Redirect Url
                  Field.
                </li>
                <li>Accept the Terms & Conditions</li>
                <li>Click on Submit and you will get Api key and Api Secret Key</li>
                <li>Copy and Paste the Api key and Api Secret key in the fields</li>
              </ul>
            </div>
          </div>
          <hr />
          <div>
            <h2 className='mb-4 text-uppercase text-info'>How to daily login ?</h2>
            <div>
              <p>Please follow below steps to daily login</p>
              <div>
                <ul>
                  <li>Open the Demat Account tab in FreeStyleFNO APP</li>
                  <li>Click on Your Upstox broker</li>
                  <li>Press on Login With Upstox Button</li>
                  <li>Enter Mobile number and click on Get OTP</li>
                  <li>Enter the OTP and press Continue</li>
                  <li>Enter your 6 Digit pin and then Continue Button</li>
                  <li>After getting Login Successfull Screen, Click On Close button</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpstoxSetupBlog
