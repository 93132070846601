/* eslint-disable jsx-a11y/anchor-is-valid */
import {useLayout} from '../../core'
import {DefaultTitle} from './page-title/DefaultTitle'
import {ThemeModeSwitcher} from '../../../partials'

const HeaderToolbar = () => {
  const {classes} = useLayout()

  return (
    <div className='toolbar d-flex align-items-stretch'>
      {/* begin::Toolbar container */}
      <div
        className={`${classes.headerContainer.join(
          ' '
        )} py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between`}
      >
        <DefaultTitle />
        {/* <div className='d-flex align-items-stretch overflow-auto pt-3 pt-lg-0'>
          <div className='d-flex align-items-center'>
            <div className='d-flex'>
              <div className='d-flex align-items-center'>
                <ThemeModeSwitcher toggleBtnClass='btn btn-sm btn-icon btn-icon-muted btn-active-icon-primary' />
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export {HeaderToolbar}
