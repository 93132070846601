import axios, {AxiosResponse} from 'axios'
import {Client, UsersQueryResponse} from './_models'
import {ID, Response} from '../../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_API_URL
const USER_URL = `${API_URL}/user`
const GET_USERS_URL = `${API_URL}/users/query`
const GET_CLIENTS_URL = `${API_URL}/admin/clients`
const GET_CLIENT_TRADES_URL = `${API_URL}/admin/tradesById`
const DELETE_CLIENT_URL = `${API_URL}/admin/delete_client`
const CALCULATE_RUNNING_PROFIT_URL = `${API_URL}/admin/calculate_running_profit`

const getClients = (query: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_CLIENTS_URL}?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getClientTradesById = (id: ID): Promise<Client | undefined> => {
  return axios.get(`${GET_CLIENT_TRADES_URL}/${id}`)
}

const getClientRunnigProfit = () => {
  return axios.get(`${CALCULATE_RUNNING_PROFIT_URL}`)
}

const getUserById = (id: ID): Promise<Client | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<Client>>) => response.data)
    .then((response: Response<Client>) => response.data)
}

const deleteClient = (id: any) => {
  return axios.delete(`${DELETE_CLIENT_URL}/${id}`)
}

export {getClients, getClientTradesById, deleteClient, getUserById, getClientRunnigProfit}
