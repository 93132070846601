import React from 'react'
import LandingLayout from '../landing/LandingLayout'

const PrivacyPolicy = () => {
  return (
    <LandingLayout>
      <section className='policy-page'>
        <div className='w-100'>
          <div className='container'>
            <div className='row h-100 align-items-center'>
              <div className='col-12'>
                <div className='position-relative'>
                  <div className='py-5'>
                    <h1 className='mb-3'>Privacy Policy</h1>
                    <p className='mb-5'>Effective Date: 01st Jan, 2024</p>

                    <h2 className='mb-3'>1. Introduction</h2>
                    <p className='mb-4'>
                      Welcome to FreeStyle InfoTech LLP ("we", "our", "us")! We are committed to
                      protecting and respecting your privacy. This Privacy Policy explains how we
                      collect, use, and disclose information about you when you use our services.
                    </p>

                    <h2 className='mb-3'>2. Information We Collect</h2>
                    <p className='mb-4'>
                      We collect information to provide better services to all our users. We collect
                      information in the following ways:
                    </p>
                    <ul className='mb-4'>
                      <li>
                        <strong>Information you give us:</strong> When you sign up for an account,
                        use our services, or communicate with us, you may provide us with personal
                        information such as your name, phone number, and date of birth.
                      </li>
                      <li>
                        <strong>Information we get from your use of our services:</strong> We may
                        collect information about the services you use and how you use them, such as
                        device information, log information, location information, and cookies.
                      </li>
                    </ul>

                    <h2 className='mb-3'>3. How We Use Information We Collect</h2>
                    <p className='mb-4'>
                      We use the information we collect for the following purposes:
                    </p>
                    <ul className='mb-4 policy_list'>
                      <li>To provide, maintain, and improve our services.</li>
                      <li>To develop new services.</li>
                      <li>To provide personalized services and recommendations.</li>
                      <li>To process transactions and send you related information.</li>
                      <li>
                        To communicate with you, including sending you updates, security alerts, and
                        support messages.
                      </li>
                      <li>
                        To monitor and analyze trends, usage, and activities in connection with our
                        services.
                      </li>
                    </ul>

                    <h2 className='mb-3'>4. Information Sharing</h2>
                    <p className='mb-4'>
                      We do not share personal information with companies, organizations, and
                      individuals outside of FreeStyle InfoTech LLP unless one of the following
                      circumstances applies:
                    </p>
                    <ul className='mb-4 policy_list'>
                      <li>
                        <strong>With your consent:</strong> We will share personal information with
                        companies, organizations, or individuals outside of FreeStyle InfoTech LLP when
                        we have your consent to do so.
                      </li>
                      <li>
                        <strong>For legal reasons:</strong> We will share personal information with
                        companies, organizations, or individuals outside of FreeStyle InfoTech LLP if we
                        have a good-faith belief that access, use, preservation, or disclosure of
                        the information is reasonably necessary to:
                        <ul className='mt-3 policy_list'>
                          <li>
                            Meet any applicable law, regulation, legal process, or enforceable
                            governmental request.
                          </li>
                          <li>
                            Enforce applicable Terms of Service, including investigation of
                            potential violations.
                          </li>
                          <li>
                            Detect, prevent, or otherwise address fraud, security, or technical
                            issues.
                          </li>
                          <li>
                            Protect against harm to the rights, property, or safety of FreeStyle
                            InfoTech, our users, or the public as required or permitted by law.
                          </li>
                        </ul>
                      </li>
                    </ul>

                    <h2 className='mb-3'>5. Security</h2>
                    <p className='mb-4'>
                      We are committed to protecting your personal information. We use a variety of
                      security technologies and procedures to help protect your personal information
                      from unauthorized access, use, or disclosure.
                    </p>

                    <h2 className='mb-3'>6. Your Choices</h2>
                    <p className='mb-4'>
                      You have the following choices regarding your personal information:
                    </p>
                    <ul className='mb-4 policy_list'>
                      <li>
                        <strong>Access and Update Your Information:</strong> You can access and
                        update your personal information through your account settings.
                      </li>
                      <li>
                        <strong>Opt-Out of Communications:</strong> You can opt-out of receiving
                        promotional communications from us by following the instructions in those
                        communications.
                      </li>
                      <li>
                        <strong>Delete Your Account:</strong> You can delete your account by
                        contacting us. Please note that we may retain certain information as
                        required by law or for legitimate business purposes.
                      </li>
                    </ul>

                    <h2 className='mb-3'>7. Changes to This Privacy Policy</h2>
                    <p className='mb-4'>
                      We may update our Privacy Policy from time to time. We will notify you of any
                      changes by posting the new Privacy Policy on this page. You are advised to
                      review this Privacy Policy periodically for any changes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </LandingLayout>
  )
}

export default PrivacyPolicy
