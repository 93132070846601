import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {getClientTradesById} from '../core/_requests'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {Client} from '../core/_models'
import {Trade} from '../../../trade-management/trades-list/core/_models'
import moment from 'moment'
import {Modal} from 'react-bootstrap'

interface LocationState {
  id: number
  client_data: Client
}

const ClientTrades = () => {
  const location = useLocation()
  const state = location.state as LocationState
  const {id, client_data} = state || {}
  const [clientTradeData, setClientTradeData] = useState<any>([])
  const [showModal, setShowModal] = useState(false)
  const [modalData, setmodalData] = useState<any>(null)

  const handleDetailsClick = (e: any, data: any) => {
    setShowModal(true)
    setmodalData(data)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const getTradeById = (id: number) => {
    getClientTradesById(id).then((res: any) => {
      setClientTradeData(res?.data?.data)
    })
  }

  useEffect(() => {
    if (id) {
      getTradeById(id)
    }
  }, [id])

  const handleBackClick = () => {
    window.history.back()
  }

  if (!id) {
    return <div>No account data found.</div>
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {clientTradeData?.length > 0
          ? clientTradeData[0]?.broker?.broker_alias
          : client_data?.full_name + ' ' + 'Trades'}
      </PageTitle>
      <div>
        <div className='card card-custom card-flush h-auto'>
          <div className='card-header justify-content-end'>
            <div className='card-toolbar'>
              <button type='button' className='btn btn-sm btn-primary' onClick={handleBackClick}>
                Back
              </button>
            </div>
          </div>
          <div className='card-body'>
            <div className='table-responsive'>
              <table className='table m-0 gs-7 gy-7 gx-7'>
                <thead>
                  <tr className='fw-bold fs-6 text-gray-800 border-top border-bottom border-gray-200.'>
                    <th className='ps-0'>Instrument Name</th>
                    <th>Quantity</th>
                    <th className='text-end'>Buy Price</th>
                    <th className='text-end'>Sell Price</th>
                    <th className='text-end'>Charges</th>
                    <th>Net P & L</th>
                    <th>Status</th>
                    <th>Date & Time</th>
                    <th>Broker Name</th>
                    <th className='pe-0'>Broker Alias</th>
                  </tr>
                </thead>
                <tbody>
                  {clientTradeData?.length > 0 ? (
                    clientTradeData?.map((item: Trade, index: number) => (
                      <tr key={index}>
                        <td className='ps-0'>
                          <span
                            className='text-primary text-capitalize cursor-pointer fw-normal'
                            onClick={(e) => handleDetailsClick(e, item)}
                          >
                            {item?.instrument?.alias}
                          </span>
                        </td>
                        <td>{item?.qty}</td>
                        <td className='text-end'>
                          <span> ₹{item?.buy_price}</span>
                        </td>
                        <td className='text-end'>
                          <span> ₹{item?.sell_price}</span>
                        </td>
                        <td className='text-end'>
                          ₹
                          {Number(Number(item?.buy_charges) + Number(item?.sell_charges)).toFixed(
                            2
                          )}
                        </td>

                        <td>
                          <span
                            className={`fs-5 fw-normal text-capitalize ${
                              Number(item?.profit) > 0
                                ? 'text-success'
                                : Number(item?.profit) < 0
                                ? 'text-danger'
                                : 'text-gray-800'
                            }`}
                          >
                            ₹{item?.profit}
                          </span>
                        </td>
                        <td>
                          <span
                            className={`badge ${
                              item?.status === 'complete'
                                ? 'badge-light-success'
                                : item?.status === 'rejected'
                                ? 'badge-light-danger'
                                : 'badge-light-warning'
                            } fs-5 text-capitalize fw-normal`}
                          >
                            {item?.status}
                          </span>
                        </td>
                        <td>
                          {item?.status === 'buy_open' ||
                          item?.status === 'open' ||
                          item?.status === 'rejected'
                            ? moment(item?.buy_order?.updatedAt).format('DD-MM-YYYY HH:mm:ss.SSS')
                            : item?.status === 'sell_open' || item?.status === 'completed'
                            ? moment(item?.sell_order?.updatedAt).format('DD-MM-YYYY HH:mm:ss.SSS')
                            : moment(item?.updatedAt).format('DD-MM-YYYY HH:mm:ss.SSS')}
                        </td>
                        <td className='pe-0'>{item?.broker?.broker_name}</td>
                        <td className='pe-0'>{item?.broker?.broker_alias}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className='text-center' colSpan={10}>
                        <h4> No Record Found</h4>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {showModal && (
            <Modal show={true} onHide={handleCloseModal} size='xl' className='text-capitalize'>
              <Modal.Header closeButton>
                <Modal.Title>Trade Details - {modalData?.instrument?.alias}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='row mb-5 gap-3 gap-lg-0'>
                  <div className='col-12 col-lg-6'>
                    <div>
                      <h2 className='mb-3'>Client Details</h2>
                      <h4>
                        Name : <span className='fw-light'>{modalData?.client?.full_name}</span>
                      </h4>
                      <h4>
                        Mobile Number :<span className='fw-light'>{modalData?.client?.mobile}</span>
                      </h4>
                    </div>
                  </div>
                  <div className='col-12 col-lg-6'>
                    <div>
                      <h2 className='mb-3'>Broker Details</h2>
                      <h4>
                        Name : <span className='fw-light'>{modalData?.broker?.broker_name}</span>
                      </h4>
                      <h4>
                        Alias : <span className='fw-light'>{modalData?.broker?.broker_alias}</span>
                      </h4>
                    </div>
                  </div>
                </div>
                <hr />
                <div className='row mb-5 gap-3 gap-lg-0'>
                  <div className='col-12 col-lg-6'>
                    <div>
                      <h2 className='mb-3'>Buy Details</h2>
                      <h4>
                        Buy price :{' '}
                        <span className='fw-light text-info'>₹{modalData?.buy_price}</span>
                      </h4>
                      <h4>
                        Order Id :{' '}
                        <span className='fw-light'>{modalData?.buy_order?.order_id}</span>
                      </h4>
                      <h4>
                        Qty : <span className='fw-light'>{modalData?.buy_order?.qty}</span>
                      </h4>
                      <h4>
                        Order Type :{' '}
                        <span className='fw-light'>{modalData?.buy_order?.order_type}</span>
                      </h4>
                      <h4>
                        Transaction Type :{' '}
                        <span className='fw-light'>{modalData?.buy_order?.transaction_type}</span>
                      </h4>
                      <h4>
                        Order Status :{' '}
                        <span className='fw-light'>{modalData?.buy_order?.status}</span>
                      </h4>
                    </div>
                  </div>
                  <div className='col-12 col-lg-6'>
                    <div>
                      <h2 className='mb-3'>Sell Details</h2>
                      <h4>
                        Sell price :{' '}
                        <span className='fw-light text-info'>₹{modalData?.sell_price}</span>
                      </h4>
                      <h4>
                        Order Id :{' '}
                        <span className='fw-light'>{modalData?.sell_order?.order_id}</span>
                      </h4>
                      <h4>
                        Qty : <span className='fw-light'>{modalData?.sell_order?.qty}</span>
                      </h4>
                      <h4>
                        Order Type :{' '}
                        <span className='fw-light'>{modalData?.sell_order?.order_type}</span>
                      </h4>
                      <h4>
                        Transaction Type :{' '}
                        <span className='fw-light'>{modalData?.sell_order?.transaction_type}</span>
                      </h4>
                      <h4>
                        Order Status :{' '}
                        <span className='fw-light'>{modalData?.sell_order?.status}</span>
                      </h4>
                    </div>
                  </div>
                </div>
                <hr />
                <div className='row mb-5 gap-3 gap-lg-0'>
                  <div className='col-12'>
                    <div>
                      <h2 className='mb-3'>Order Details</h2>
                      <h4>
                        Sl Trigger Price :{' '}
                        <span className='fw-light text-info'>₹{modalData?.sl_trigger_price}</span>
                      </h4>
                      <h4>
                        Sl Price :{' '}
                        <span className='fw-light text-info'>₹{modalData?.sl_price}</span>
                      </h4>
                      <h4>
                        Target Price :{' '}
                        <span className='fw-light text-info'>₹{modalData?.target_price}</span>
                      </h4>
                      <h4>
                        Total Charges :{' '}
                        <span className='fw-light text-info'>
                          ₹
                          {Number(
                            Number(modalData?.buy_charges) + Number(modalData?.sell_charges)
                          ).toFixed(2)}
                        </span>
                      </h4>
                      <h4>
                        Net Profit :{' '}
                        <span
                          className={`fw-light ${
                            modalData?.status == 'complete'
                              ? Number(modalData?.sell_price) >= Number(modalData?.buy_price)
                                ? 'text-success'
                                : 'text-danger'
                              : 'text-info'
                          }  `}
                        >
                          ₹{modalData?.profit}
                        </span>
                      </h4>
                      <h4>
                        Order Status : <span className='fw-light'>{modalData?.status}</span>
                      </h4>
                      <h4>
                        Created At :{' '}
                        <span className='fw-light'>
                          {moment(modalData?.createdAt).format('DD-MM-YYYY  HH:mm:ss')}
                        </span>
                      </h4>
                      <h4>
                        Updated AT :{' '}
                        <span className='fw-light'>
                          {' '}
                          {moment(modalData?.updatedAt).format('DD-MM-YYYY  HH:mm:ss')}
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          )}
        </div>
      </div>
    </>
  )
}

export default ClientTrades
